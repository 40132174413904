import React from "react"

export default function Section4() {
  return <div className="lg:flex w-full py-20">
        <div className="w-full self-center py-10">
         <div className="bg-fixed bg-opacity-25 h-600 content-end p-20" style={{
           backgroundImage: "url(https://www.meistertask.com/blog/wp-content/uploads/2017/06/team-pic.jpg)",
           backgroundSize: "cover",
          }}>
            <div className="divider bg-accent my-8 mx-auto"></div>
            <p className="text-4xl leading-loose text-white my-5 text-center text-aileron-bold ">
              With our centralized “Share-and-Go”, Ciergio adds value by empowering your community and members into coordinated action
            </p>
        </div>
        </div>
      </div>

}